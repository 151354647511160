<template>
  <div class="w-full p-12 mx-12 my-auto">
    <div class="mb-10 text-center">
      <h1>Sign in</h1>
    </div>
    <form @submit.prevent="login" @keydown="form.onKeydown($event)">
      <!-- Email -->
      <div class="form-group row">
        <label>{{ $t('email') }}</label>
        <input
          v-model="form.email"
          :class="{ 'is-invalid': form.errors.has('email') }"
          class="form-control"
          type="email"
          name="email"
          placeholder="your@email.com"
        >
        <has-error :form="form" field="email" />
      </div>

      <!-- Password -->
      <div class="form-group">
        <div class="flex">
          <label>{{ $t('password') }}</label>
          <router-link
            :to="{ name: 'password.request' }"
            class="my-auto ml-auto text-xs text-gray-500"
          >
            {{ $t('forgot_password') }}
          </router-link>
        </div>
        <input
          v-model="form.password"
          :class="{ 'is-invalid': form.errors.has('password') }"
          class="form-control"
          type="password"
          name="password"
          placeholder="Enter your password"
        >
        <has-error :form="form" field="password" />
      </div>

      <!-- Remember Me -->
      <div class="form-group">
        <div class="flex">
          <label>
            <input v-model="remember" type="checkbox" name="remember">
            <span class="text-sm ml-1">{{ $t('remember_me') }}</span>
          </label>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-7 offset-md-3 d-flex">
          <!-- Submit Button -->
          <button class="w-full btn">
            {{ $t('login') }}
          </button>
          <button type="button" class="w-full mt-4 btn-transparent flex justify-center items-center" @click="azureLogin">
            <span class="mr-4">Log In With Azure</span>
            <AzureIcon />
          </button>
          <div v-if="azureMessage" class="mt-2 text-red-500">
            {{ azureMessage }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import AzureIcon from '~/components/icons/azure-icon.vue';

export default {
  layout: 'login',

  components: { AzureIcon },

  metaInfo () {
    return { title: this.$t('login') }
  },

  data: () => ({
    form: new Form({
      email: '',
      password: ''
    }),
    remember: false,
    azureMessage: '',
  }),

  methods: {
    async login () {
      // Submit the form.
      const { data } = await this.form.post(this.$apiUrl.login)

      this.enterSystem(data.token);
    },

    async azureLogin() {
      location.replace(this.$apiUrl.loginMS);
    },

    async enterSystem(token) {
      // // Save the token.
      this.$store.dispatch('auth/saveToken', {
        token: token,
        remember: this.remember
      });

      // // Fetch the user.
      await this.$store.dispatch('auth/fetchUser');

      const user = await axios.get(this.$apiUrl.whoami);

      this.$cookies.set('sites', user.data.data.sitesFilter?.map(site => site.key).join(',') || '');

      this.$cookies.set('brand_color', user.data.data.organization.brand_color);
      this.$cookies.set('logo_url', user.data.data.organization.logo_url);

      // Select all sites if you are a regular user
      if (user.data.data.level !== 'super') {
        await axios.post('/api/users/sites-filter', {
          site_ids: user.data.data.site_ids,
        });
      }

      if (user.data.level === 'super') {
        this.$cookies.set('brand_color', '#2E457C');
      }

      if (user.data.data.level === 'super') {
        localStorage.setItem('role', 'admin');

        this.$store.commit("sidebar/ADMIN_MENU", 'admin');

        this.$store.commit("sidebar/LAST_ADMIN_PATH", 'admin.shipments');

        this.$cookies.set('brand_color', '#2E457C');

        window.location = '/admin/shipments/?resetData=true';

        return;
      }

      // // Redirect home.
      location.reload();
      this.$router.push({ name: 'home' });
    }
  },
  async created() {
    this.azureMessage = '';

    if (this.$route.query.error) {
      this.azureMessage = this.$route.query.error;

      return;
    }

    if (this.$route.query.token) {
      await this.enterSystem(this.$route.query.token);

      this.azureMessage = '';
    }
  }
}
</script>
